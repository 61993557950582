if (LiveForm) {
   LiveForm.setOptions({
      messageErrorPrefix: '<i class="ico-exclamation-sign-circle" aria-hidden="true"></i>'
   });
}

function setCookie(cname, cvalue, exdays) {
   var d = new Date();
   d.setTime(d.getTime() + (exdays*24*60*60*1000));
   var expires = "expires="+ d.toUTCString();
   document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
   var name = cname + "=";
   var decodedCookie = decodeURIComponent(document.cookie);
   var ca = decodedCookie.split(';');
   for(var i = 0; i <ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
         c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
         return c.substring(name.length, c.length);
      }
   }
   return "";
}

$(document).ready(() => {
   // cookies panel
   console.log(getCookie('cookies-allowed'));
   if(getCookie('cookies-allowed') != "1"){
      $('#cookies-information').show();
   }
   $('#allow-cookies-button').on('click', () => {
      $('#cookies-information').hide();
      setCookie('cookies-allowed', '1', 365*5);
   });
});

// js init on load and after rerender snippet by ajax
function registerScopeJs(scope) {
   const alerts = [...document.querySelectorAll(".alert-dismissible")];
   if (alerts.length > 0) {
      alerts.forEach(alert => {
         const closeBtn = alert.querySelector("button.close");

         if (closeBtn) {
            closeBtn.addEventListener("click", () => (alert.style.display = "none"));
         }
      });
   }
}
